import OilProductsSection from "../Sections/OilProductsSection/OilProductsSection";

const BaseProductOils = () => {
    return (
        <>
            <OilProductsSection />
        </>
    )
}

export default BaseProductOils;